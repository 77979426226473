import React from "react"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import '../css/navbar-main.css'
import { Link } from "react-router-dom"

const NavbarMain = ({ menuoptions }) => {
    return (
        <Navbar menuoptions={menuoptions} expand="lg" className="px-0 container-fluid">
            <Navbar.Brand href="/">
                <img className="align-left" src="../img/logo.png" alt="psyd chat" width="75" height="75" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto navbar-right">
                    <Nav.Link href={menuoptions.home.link}>{menuoptions.home.name}</Nav.Link>
                    <Nav.Link href={menuoptions.process.link}>{menuoptions.process.name}</Nav.Link>
                    <Nav.Link href={menuoptions.plans.link}>{menuoptions.plans.name}</Nav.Link>
                    <Nav.Link href={menuoptions.specializations.link}>{menuoptions.specializations.name}</Nav.Link>
                    <Nav.Link href={menuoptions.about.link}>{menuoptions.about.name}</Nav.Link>
                    <Nav.Link href={menuoptions.login.link}>{menuoptions.login.name}</Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>

    )
}

export default NavbarMain
