import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"
import GoogleAuth from './googleauth';



const Process = () => {
  const location = useLocation();

  return (
    <div id='homediv' className="container-fluid px-0">
      <div className="container-fluid px-4 shadow-lg rounded">
        <div className="row mt-2 mb-0 rounded">
          <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
            <h1>How it Works?</h1>
          </div>
        </div>
        <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
          <div className="col-md-6 p-0 d-flex justify-content-center">
            <div className="card">
              <img
                className="card-img-top img-fluid "
                src="../img/5138915.jpg" // Replace with your image path
                alt="Guest Session"
              />
            </div>
          </div>
          <div className="col-md-6 p-3 mb-2 psyd-secondary-bg-color psyd-font">
            To get started, you'll provide some basic demographic information, such as your age group and professional status, which helps our AI tailor the experience to your needs. You can begin your journey with a Guest session, allowing you to explore key features without needing an account—perfect for those wanting a taste of our service before committing.
            <p />
            <div className="card p-3 rounded psyd-primary-bg-color psyd-font-light">
              <div className="card-body">
                <h5 className="card-title">Session Interaction:</h5>
                <p className="card-text">
                  During a session, you'll interact through a chat interface where the AI will engage you by asking questions and providing strategies based on the information you share. This dynamic interaction allows for a personalized experience, helping you reflect on your thoughts and feelings in real time.
                </p>
              </div>
            </div>
            <p />
            If you choose to create a free account using your Google account, you unlock a host of enhanced features that elevate your therapy experience. With an account, you can easily review or continue past sessions, enabling deeper exploration over time. Additionally, your secure account serves as a space to track progress, reflect on previous conversations, and build on your therapeutic journey. Join us today and take the first step toward better mental health!
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <Link to={'/sessions'} style={{ display: 'flex', justifyContent: 'center' }}>
          <Button>Start a Session</Button>
        </Link>
      </div>
    </div >
  );
};

export default Process;
