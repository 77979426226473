import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const Consent = () => {
    return (

        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Important Terms and Privacy Information</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3">
                        <div>
                            We are excited to offer you a unique experience powered by AI, but it's important to note that our platform is still in the testing phase. While we aim to provide helpful support and resources, this service is not a substitute for a qualified licensed mental health professional. If you are experiencing a mental health crisis or require immediate assistance, we strongly encourage you to seek help from a licensed therapist or counselor.
                        </div>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3">
                        <div>
                            As you navigate the platform, please remember to avoid sharing any personally identifiable information, such as address, or contact details. Your privacy is essential, and we want to ensure a safe environment for all users. The information you do share may be utilized to improve and train our platform further, helping us enhance the experience for future users.
                        </div>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-primary-bg-color psyd-font-light align-items-center rounded">
                    <div className="col-md-12 p-3">
                        <div>
                            By clicking the "Next" button, you are providing your consent to these terms. We appreciate your understanding and support as we work to create a valuable resource for mental health and well-being. Thank you for being part of this journey!
                        </div>
                    </div>
                    <br />
                    <Link to={'/messages'} style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button>Next</Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Consent;