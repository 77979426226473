import React, { useEffect } from 'react';
import { useLocation, Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ProcessLogin = ({ changeMenuOption }) => {
  const location = useLocation();

  useEffect(() => {
    changeMenuOption('login', 'Profile', '/profile');
  }, [changeMenuOption]); // Ensure it runs once on mount

  return (
    <div id='homediv' className="container-fluid px-0">
    <div className="container-fluid px-4 shadow-lg rounded">
        <div className="row mt-2 mb-0 rounded">
            <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                <h1>Login</h1>
          </div>
        </div>
        <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center">
          <div className="col p-1">
            Your login was processed successfully. Please start a new session.
          </div>
          <Link to='/sessions' style={{ display: 'flex', justifyContent: 'center' }}>
            <Button>Start a Session</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default ProcessLogin;
