import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Container } from 'react-bootstrap';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

const User = () => {
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        preferred_name: '',
        date_of_birth: '',
        gender: '',
        pronouns: '',
        shared_with_email: '',
        shared_with_user_id: '',
        sharing_authorization_code: ''
    });

    // Handle form field change
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            setFormData(userinfo);  // Set form data from the fetched user
        }
        setErrorMessage('if you would like to share your sessions with another user, please prvide their PSYD email id and authorization code.');
    }, []);

    const handleVerify = async () => {
        try {
            if (formData.shared_with_email && formData.sharing_authorization_code && formData.sharing_authorization_code !== 'None') {
                const response = await axios.get(`/api/verifyshareduser?useremail=${formData.shared_with_email}&sharingcode=${formData.sharing_authorization_code}`);
                if (response.data.exists) {
                    formData.shared_with_user_id = response.data.sharedwithuserid;
                    setErrorMessage('User successfully validated');
                } else {
                    setErrorMessage('The email and Sharing Authorization Code did not match');
                }
            } else {
                setErrorMessage('Please provide valid values for Shared with User email and Sharing Authorization Code');
            }
        } catch (error) {
            setErrorMessage(error);
        }
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        console.error(formData);
        const userCookie = Cookies.get('userinfo');
        let userinfo = {};
        let userId = ''
        if (userCookie) {
            userinfo = JSON.parse(userCookie);
            userId = userinfo.user_id;
        }
        axios.put('/api/user?userId=${userId}', formData) // PUT request to update the user
            .then((response) => {
                const userinfo = JSON.parse(response.data.user);
                console.log('userinfo:', userinfo);
                Cookie.set('userinfo', JSON.stringify(userinfo));
                setErrorMessage('Profile successfully updated.');

            })
            .catch((error) => {
                console.error('Error updating user:', error);
            });
    };

    return (
        <Container>
            <h2>Update User</h2>
            <Form>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>First Name</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="first_name" value={formData.first_name} onChange={handleChange} required style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Last Name</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="last_name" value={formData.last_name} onChange={handleChange} required style={{ height: '55px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Preferred Name</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="preferred_name" value={formData.preferred_name} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Date of Birth</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Pronouns</label>
                    </div>
                    <div className="col-md-3">
                        <select className="form-select" name="pronouns" value={formData.pronouns} onChange={handleChange} style={{ height: '55px' }} >
                            <option value="">Select Pronouns</option>
                            <option value="he/him/his">He/Him/His</option>
                            <option value="she/her/hers">She/Her/Hers</option>
                            <option value="they/them/theirs">They/Them/Theirs</option>
                            {/* Add more options as needed */}
                        </select>                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Gender</label>
                    </div>
                    <div className="col-md-3">
                        <select className="form-select" name="gender" value={formData.gender} onChange={handleChange} style={{ height: '55px' }} >
                            <option value="">Select Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Non-Binary">Non-Binary</option>
                            <option value="Prefer Not to Say">Prefer Not to Say</option>
                            {/* Add more options as needed */}
                        </select>                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-12 rounded psyd-primary-bg-color">
                        <div className='psyd-font-light'>{errorMessage}</div>
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Shared with User email</label>
                    </div>
                    <div className="col-md-3">
                        <input className="form-control" type="text" name="shared_with_email" value={formData.shared_with_email} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-3 p-3 rounded psyd-secondary-bg-color">
                        <label>Sharing Authorization Code</label>
                    </div>
                    <div className="col-md-2">
                        <input className="form-control" type="text" name="sharing_authorization_code" value={formData.sharing_authorization_code} onChange={handleChange} style={{ height: '55px' }} />
                    </div>
                    <div className="col-md-1 d-flex justify-content-center align-items-center">
                        <Button onClick={handleVerify} style={{ height: '55px' }}>Verify User</Button>
                    </div>
                </div>
                <div className="row mb-3 g-2">
                    <div className="col-md-12 rounded psyd-primary-bg-color d-flex justify-content-center align-items-center">
                        <Button variant="primary" onClick={handleSubmit}>
                            Update User
                        </Button>
                    </div>
                </div>
            </Form>
        </Container>
    );
};

export default User;
