let luxuryTheme = {
    colors: {
        background: '#1d3a35',
        secondary: '#b5d59b',
        tertiary: '#0b140e'
    },
    font: {
        color: '#a28644'
    }
}

export default luxuryTheme