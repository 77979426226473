import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
// Set axios default settings to include cookies
axios.defaults.withCredentials = true;


function Plans() {
    const [userId, setUserID] = useState('');
    const [guestPlans, setGuestPlans] = useState([]);
    const [guestPlansPricing, setGuestPlansPricing] = useState([]);

    const [freePlans, setFreePlans] = useState([]);
    const [freePlansPricing, setFreePlansPricing] = useState([]);

    const [basicPlans, setBasicPlans] = useState([]);
    const [basicPlansPricing, setBasicPlansPricing] = useState([]);

    const [premiumPlans, setPremiumPlans] = useState([]);
    const [premiumPlansPricing, setPremiumPlansPricing] = useState([]);

    const [ultimatePlans, setUltimatePlans] = useState([]);
    const [ultimatePlansPricing, setUltimatePlansPricing] = useState([]);

    const [familyPlans, setFamilyPlans] = useState([]);
    const [familyPlansPricing, setFamilyPlansPricing] = useState([]);


    const fetchPlans = async () => {
        try {

            let userid_cookie = Cookies.get('userid')
            if (userid_cookie) { setUserID(userid_cookie) }

            const response = await axios.get('/api/allplans');
            const tempplans = response.data.plans;
            const parsedPlans = tempplans?.map(tempplan => JSON.parse(tempplan)) || [];
            // Filter for plans with subscription_name 'Free Plan'
            const guestPlans = parsedPlans.filter(plan => plan.subscription_name === 'Guest Plan');
            setGuestPlans(guestPlans);
            setGuestPlansPricing(guestPlans[0]);

            const freePlans = parsedPlans.filter(plan => plan.subscription_name === 'Free Plan');
            setFreePlans(freePlans);
            setFreePlansPricing(freePlans[0]);

            const basicPlans = parsedPlans.filter(plan => plan.subscription_name === 'Basic Plan');
            setBasicPlans(basicPlans);
            setBasicPlansPricing(basicPlans[0]);

            const premiumPlans = parsedPlans.filter(plan => plan.subscription_name === 'Premium Plan');
            setPremiumPlans(premiumPlans);
            setPremiumPlansPricing(premiumPlans[0]);

            const ultimatePlans = parsedPlans.filter(plan => plan.subscription_name === 'Ultimate Plan');
            setUltimatePlans(ultimatePlans);
            setUltimatePlansPricing(ultimatePlans[0]);

            const familyPlans = parsedPlans.filter(plan => plan.subscription_name === 'Family Plan');
            setFamilyPlans(familyPlans);
            setFamilyPlansPricing(familyPlans[0]);


        } catch (error) {
            console.error('Error fetching plans', error);
        }
    };

    useEffect(() => {
        fetchPlans();
    }, []);
    return (
        <div id='homediv' className="container px-0">
            <div className="container px-3 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-md-3 psyd-accent-bg-color border border-3 p-3 psyd-font-accent rounded-top">
                        Therapy Options
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>
            <div className="container px-3">
                <div className="row mt-0 psyd-secondary-bg-color align-items-center">
                    <div className="col-md-6 d-flex align-items-center psyd-secondary-bg-color">
                        <div className="card p-2">
                            <img
                                className="card-img-top img-fluid"
                                src="../img/subscribe.jpg" // Replace with your image path
                                alt="Guest Session"
                            />
                        </div>
                    </div>
                    <div className="col-md-6 p-1 mb-2 psyd-secondary-bg-color psyd-font">
                        Our therapy platform offers a range of flexible plans to accommodate different needs: <br />
                        <b>Guest Plan</b>, which allows 30 minutes of therapy time per month without session history retention. <br />
                        <b>Free Plan</b> increases access to 120 minutes of therapy time per month and retains history for three months, also at no cost. <br />
                        <b>Basic Plan</b> ($9.99/month) provides 240 minutes of therapy time per month and 12 months of history, while <br />
                        <b>Premium Plan</b> ($19.99/month) offers 480 minutes of therapy time per month with 24 months of history retention.<br />
                        <b>Ultimate Plan</b> ($29.99/month) includes 1800 minutes of therapy time per month and a 36-month history retention. <br />
                        <b>Family Plan</b> ($49.99/month) allows families to access therapy together with the same generous therapy time per month. <br />
                        Each plan features personalized therapy, customizable interfaces, and additional resources to support your mental health journey.
                    </div>
                </div>
                <div className="container px-0 shadow-lg rounded">
                    <div className="row mt-2 mb-0 rounded">
                        <div className="col-md-3 psyd-accent-bg-color border border-3 p-3 psyd-font-accent rounded-top">
                            Therapy Time
                        </div>
                        <div className="col">
                        </div>
                        <div className="col">
                        </div>
                    </div>
                </div>

                <div className="row mt-0 psyd-secondary-bg-color align-items-center">
                    <div className="col-md-6 p-1 mb-2 psyd-secondary-bg-color psyd-font">
                        The total therapy duration is calculated from the time between the first and last message timestamps, excluding any breaks longer than 5 minutes. This allows you to take breaks without impacting the total therapy time. You can divide your total interaction time across multiple sessions, giving you the flexibility to start as many sessions as you need without being limited by the number specified in your plan. This approach helps you manage your therapy in a way that fits your schedules while still fulfilling the total minutes in your plan.
                    </div>
                    <div className="col-md-6 d-flex align-items-center psyd-secondary-bg-color">
                        <div className="card p-2 psyd-secondary-bg-color">
                            <img
                                className="card-img-top img-fluid "
                                src="../img/clock.jpg" // Replace with your image path
                                alt="Guest Session"
                            />
                        </div>
                    </div>

                </div>
            </div>
            <div className="container px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-3 psyd-accent-bg-color border border-3 p-3 psyd-font-accent rounded-top">
                        Subscription Plans
                    </div>
                    <div className="col">
                    </div>
                    <div className="col">
                    </div>
                </div>
            </div>

            <div className="container px-2 psyd-font rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-4">
                        <div className="px-0 mb-0 border border-bottom-0 border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Guest Plan
                        </div>
                        <div className="px-1 mt-0 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Monthly Pricing: {guestPlansPricing.price_monthly}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Annual Pricing: {guestPlansPricing.price_annual}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Therapy time per month: {guestPlansPricing.sessions_per_month}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Sessions History Retention Periond: {guestPlansPricing.session_history_retention_period}</div>
                            <div className="px-1 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul className="mx-0 px-0">
                                {guestPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {guestPlans?.map((guestPlan, index) => (
                                    <li class="psyd-chat px-1 psyd-secondary-bg-color psyd-font">
                                        <div key={guestPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font" style={{ width: '90%' }}>
                                                    {guestPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={guestPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="px-0 mb-0 border border-bottom-0 border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Free Plan
                        </div>
                        <div className="px-1 mt-0 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Monthly Pricing: {freePlansPricing.price_monthly}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Annual Pricing: {freePlansPricing.price_annual}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Therapy time per month: {freePlansPricing.sessions_per_month}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Sessions History Retention Periond: {freePlansPricing.session_history_retention_period}</div>
                            <div className="px-1 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul className="mx-0 px-0">
                                {freePlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {freePlans?.map((freePlan, index) => (
                                    <li class="psyd-chat px-1 psyd-secondary-bg-color psyd-font">
                                        <div key={freePlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font" style={{ width: '90%' }}>
                                                    {freePlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={freePlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="px-0 mb-0 border border-bottom-0 border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Basic Plan
                        </div>
                        <div className="px-1 mt-0 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Monthly Pricing: {basicPlansPricing.price_monthly}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Annual Pricing: {basicPlansPricing.price_annual}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Therapy time per month: {basicPlansPricing.sessions_per_month}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Sessions History Retention Periond: {basicPlansPricing.session_history_retention_period}</div>
                            <div className="px-1 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul className="mx-0 px-0">
                                {basicPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {basicPlans?.map((basicPlan, index) => (
                                    <li class="psyd-chat px-1 psyd-secondary-bg-color psyd-font">
                                        <div key={basicPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font" style={{ width: '90%' }}>
                                                    {basicPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={basicPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container px-2 psyd-font rounded mt-4">
                <div className="row justify-content-md-center">
                    <div className="col-md-4">
                        <div className="px-0 mb-0 border border-bottom-0 border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Premium Plan
                        </div>
                        <div className="px-1 mt-0 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Monthly Pricing: {premiumPlansPricing.price_monthly}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Annual Pricing: {premiumPlansPricing.price_annual}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Therapy time per month: {premiumPlansPricing.sessions_per_month}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Sessions History Retention Periond: {premiumPlansPricing.session_history_retention_period}</div>
                            <div className="px-1 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul className="mx-0 px-0">
                                {premiumPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {premiumPlans?.map((premiumPlan, index) => (
                                    <li class="psyd-chat px-1 psyd-secondary-bg-color psyd-font">
                                        <div key={premiumPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font" style={{ width: '90%' }}>
                                                    {premiumPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={premiumPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="px-0 mb-0 border border-bottom-0 border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Ultimate Plan
                        </div>
                        <div className="px-1 mt-0 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Monthly Pricing: {ultimatePlansPricing.price_monthly}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Annual Pricing: {ultimatePlansPricing.price_annual}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Therapy time per month: {ultimatePlansPricing.sessions_per_month}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Sessions History Retention Periond: {ultimatePlansPricing.session_history_retention_period}</div>
                            <div className="px-1 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul className="mx-0 px-0">
                                {ultimatePlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {ultimatePlans?.map((ultimatePlan, index) => (
                                    <li class="psyd-chat px-1 psyd-secondary-bg-color psyd-font">
                                        <div key={ultimatePlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font" style={{ width: '90%' }}>
                                                    {ultimatePlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={ultimatePlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="px-0 mb-0 border border-bottom-0 border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                            Family Plan
                        </div>
                        <div className="px-1 mt-0 border border-top-0 border-3 rounded-left rounded-right">
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Monthly Pricing: {familyPlansPricing.price_monthly}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Annual Pricing: {familyPlansPricing.price_annual}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Therapy time per month: {familyPlansPricing.sessions_per_month}</div>
                            <div className="px-1 psyd-secondary-bg-color psyd-font">Sessions History Retention Periond: {familyPlansPricing.session_history_retention_period}</div>
                            <div className="px-1 psyd-primary-bg-color psyd-font-light border-top border-3">Additional Features:</div>
                            <ul className="mx-0 px-0">
                                {familyPlans.length === 0 && <p className="text-center text-muted">No Plans yet</p>}
                                {familyPlans?.map((familyPlan, index) => (
                                    <li class="psyd-chat px-1 psyd-secondary-bg-color psyd-font">
                                        <div key={familyPlan.id}>
                                            <div className="d-flex mb-0 mt-0 w-100"> {/* Small margin for input_text */}
                                                <div className="p-0 mr-2 justify-content-start rounded  psyd-font" style={{ width: '90%' }}>
                                                    {familyPlan.feature_name}
                                                </div>
                                                <div className="p-0 mr-2 justify-content-start rounded" style={{ width: '10%' }}>
                                                    <input type="checkbox" checked={familyPlan.feature_active === "True" ? "checked" : ""} disabled />
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <div className="container px-1 mt-1 justify-content-md-center psyd-primary-bg-color button-block text-center">

                    {userId ? (
                        //<Link to='/purchase'>
                        <Button disabled>Purchase a Plan</Button>
                        //</Link>
                    ) : (
                        <Button disabled>Purchase a Plan</Button> // Disabled button when userId doesn't exist
                    )}
                </div>

            </div>
        </div>
    );
}

export default Plans;
