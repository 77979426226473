import GoogleAuth from './googleauth';
import FacebookAuth  from './facebookauth';

const SignUp = () => {
    return (
        <div>
            <div>
                <GoogleAuth />
            </div>
            <div>
                <FacebookAuth />
            </div>
        </div>
    )
}

export default SignUp;