import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

const Personas = () => {
    const [refvalues, setRefValues] = useState([]);
    const [therapistpersonaID, setTherapistPersonaID] = useState(null);

    const fetchTherapistPersona = async () => {
        try {
            const response = await axios.get('/api/alltherapistpersona');
            const tempRefValues = response.data.refvalues;
            const parsedRefValues = tempRefValues?.map(tempRefValue => JSON.parse(tempRefValue)) || [];
            console.error(parsedRefValues);
            setRefValues(parsedRefValues);
        } catch (error) {
            console.error('Error fetching therapist personas', error);
        }
    };

    const handleCardClick = (id) => {
        setTherapistPersonaID(id);
        Cookie.set('therapistpersonaid', id);
        console.log("Selected therapist persona:", id);
    };

    useEffect(() => {
        const sessionInfoCookie = Cookies.get('sessioninfo');
        if (sessionInfoCookie) {
            const sessionInfo = JSON.parse(sessionInfoCookie);
            setTherapistPersonaID(sessionInfo.concern_id);
        } else {
            const cookieID = Cookie.get('therapistpersonaid');
            if (cookieID) {
                setTherapistPersonaID(cookieID);
            }
        }
        fetchTherapistPersona();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Therapist Characters</h1>
                    </div>
                </div>
                <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                    <div className="col p-1 rounded">
                        You can choose one of the following characters as your therapist.
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 psyd-primary-bg-color">
                <Row className="justify-content-start">
                    {refvalues?.map((refvalue, index) => (
                        <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-3">
                            <Card
                                className={`text-left psyd-secondary-bg-color cursor-pointer ${therapistpersonaID === refvalue.persona_id ? 'border border-primary' : ''}`}
                                onClick={() => handleCardClick(refvalue.persona_id)}
                                style={{ height: '350px' }} // Set a fixed height for the card
                            >
                                <img
                                    src={`./img/${refvalue.default_photo_url}`}
                                    className="card-img-top fixed-image-size" // Ensure the fixed-image-size class is applied
                                    alt={refvalue.default_name}
                                />
                                <Card.Body className="d-flex flex-column"> {/* Make Card.Body a flex container */}
                                    <Card.Title>{refvalue.default_name}</Card.Title>
                                    <Card.Text className="flex-grow-1">{refvalue.persona_description.substring(0, 300)}</Card.Text>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={therapistpersonaID === refvalue.persona_id}
                                            readOnly
                                        />
                                        <label className="form-check-label">Select</label>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
            <br />
            <Link to={'/messages'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Next</Button>
            </Link>
        </div>
    );
}

export default Personas;
