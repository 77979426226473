import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';



const AgeGroup = () => {
    const [refvalues, setrefvalues] = useState([]);
    const [agegroupid, setAgeGroupID] = useState(1);

    const fetchAgeGroups = async () => {
        try {

            const response = await axios.get('/api/allagegroups');
            const temprefvalues = response.data.refvalues;
            const parserefvalues = temprefvalues?.map(temprefvalue => JSON.parse(temprefvalue)) || [];
            setrefvalues(parserefvalues);
        } catch (error) {
            console.error('Error fetching age groups', error);
        }
    };
    // Function to handle radio button selection
    const handleRadioChange = (event) => {
        setAgeGroupID(event.target.value);
        console.log("Selected age group:", event.target.value);
        Cookie.set('agegroupid', event.target.value);
    };
    useEffect(() => {
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setAgeGroupID(sessioninfo.age_group_id);
        }
        fetchAgeGroups();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Age Groups</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        Age is a significant factor in determining health and the effectiveness of therapy. As individuals age, their physical and mental health needs evolve. Younger individuals may face unique challenges related to identity, education, and career development, while older adults may grapple with issues such as chronic illnesses, retirement, and loss. These differences necessitate tailored therapeutic approaches. For example, therapy for young adults might focus on building coping skills and developing healthy relationships, while therapy for older adults could involve strategies for managing chronic conditions and promoting emotional well-being.
                    </div>
                </div>
            </div>
            <div className="container mt-3 psyd-primary-bg-color d-flex justify-content-center align-items-top" style={{ minHeight: '50vh' }}>
                <div className="col">
                    <div className="list-group p-0 d-flex justify-content-center align-items-top">
                        {refvalues?.map((refvalue, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                id={`refvalue-${index}`}
                                label={
                                    <span style={{ marginLeft: '20px' }}>{refvalue.age_group}</span> // Add spacing here
                                }
                                value={refvalue.age_group_id}
                                name="refvalue" // Grouping the radio buttons
                                className="psyd-secondary-bg-color rounded mb-2"
                                onChange={handleRadioChange}
                                checked={agegroupid == refvalue.age_group_id}
                                style={{ height: '60px', display: 'flex', alignItems: 'center' }} // Adjust height and center alignment
                            />
                        ))}
                    </div>
                </div>
            </div>
            <br />
            <Link to={'/relationshipstatus'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Next</Button>
            </Link>

        </div>
    );
}

export default AgeGroup;
