let starryTheme = {
    colors: {
        background: '#cfdef8',
        secondary: '#f0c778',
        tertiary: '#4e698f'
    },
    font: {
        color: 'black'
    }
}

export default starryTheme