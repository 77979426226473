// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App div {
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 10px;
}

.App-link {
  color: #61dafb;
}

/* styles.css */
.scrollable-div {
  width: 700px;   /* Adjust the width as needed */
  height: 200px;  /* Adjust the height as needed */
  border: 1px solid #ccc;
  padding: 10px;
  overflow: auto; /* Enables scrolling */
  box-sizing: border-box;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* html{
  height: 100%;
}

body{
  height: 100%;
}

div#root{
  height: 100%;
}

 */`, "",{"version":3,"sources":["webpack://./src/css/app.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,oBAAoB;AACtB;;AAEA;EACE;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,qBAAqB;EACrB,6BAA6B;EAC7B,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA,eAAe;AACf;EACE,YAAY,IAAI,+BAA+B;EAC/C,aAAa,GAAG,gCAAgC;EAChD,sBAAsB;EACtB,aAAa;EACb,cAAc,EAAE,sBAAsB;EACtC,sBAAsB;AACxB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;;AAGA;;;;;;;;;;;;EAYE","sourcesContent":[".App div {\n  height: 100%;\n}\n\n.App-logo {\n  height: 40vmin;\n  pointer-events: none;\n}\n\n@media (prefers-reduced-motion: no-preference) {\n  .App-logo {\n    animation: App-logo-spin infinite 20s linear;\n  }\n}\n\n.App-header {\n  background-color: #282c34;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  align-items: left;\n  justify-content: left;\n  font-size: calc(10px + 2vmin);\n  color: white;\n  padding: 10px;\n}\n\n.App-link {\n  color: #61dafb;\n}\n\n/* styles.css */\n.scrollable-div {\n  width: 700px;   /* Adjust the width as needed */\n  height: 200px;  /* Adjust the height as needed */\n  border: 1px solid #ccc;\n  padding: 10px;\n  overflow: auto; /* Enables scrolling */\n  box-sizing: border-box;\n}\n\n@keyframes App-logo-spin {\n  from {\n    transform: rotate(0deg);\n  }\n  to {\n    transform: rotate(360deg);\n  }\n}\n\n\n/* html{\n  height: 100%;\n}\n\nbody{\n  height: 100%;\n}\n\ndiv#root{\n  height: 100%;\n}\n\n */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
