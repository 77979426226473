import { useState } from "react"
import { Button } from "react-bootstrap"
import { Link } from "react-router-dom"
import ListGroup from 'react-bootstrap/ListGroup';
import '../css/home.css'
import Process from "./process";

const Home = () => {

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>AI Powered Therapy</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card">
                            <img className="card-img-top img-fluid" src="../img/Hands.jpg" alt="Online Therapy" />
                        </div>
                    </div>
                    <div className="col-md-6 p-3">
                        <p>
                            Feeling overwhelmed or just need someone to talk to? It can be tough to find the right mental health support when you need it. That's where we come in.
                            <p></p>
                            <div className="card p-3 rounded psyd-primary-bg-color psyd-font-light">
                                <div className="card-body">
                                    <p className="card-text">
                                        Our platform combines the compassion and expertise of licensed clinical psychologists with the power of cutting-edge AI. This means you get personalized guidance and support, whenever you need it, without the wait.
                                    </p>
                                </div>
                            </div>
                            <p></p>
                            Our AI has been specially trained to understand your concerns and offer helpful responses, making it easier than ever to prioritize your mental well-being. Whether you're dealing with stress, anxiety, or other mental health challenges, this AI-powered therapist can provide guidance, coping strategies, and a safe space to explore your thoughts and feelings. PSYD's mission is to make Mental Health Support available to Anyone, Anywhere, Anytime!
                        </p>
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-5 px-4 shadow-lg rounded">
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-6 p-0 d-flex justify-content-center">
                        <div className="card w-100 psyd-secondary-bg-color border-0 px-0">
                            <div className="card-body">
                                <ListGroup className="p-0">
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>24/7 Availability</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Personalized Therapy</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Private and Unbiased</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Flexible Session Options</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Automated Progress Tracking*</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Therapy Session Summaries*</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Goal Setting and Monitoring*</b></ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-2 bi bi-check-circle">&nbsp;<b>Human Therapists Integration*</b></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0 d-flex justify-content-center align-items-start">
                        <div className="card w-100">
                            <img className="card-img-top img-fluid" src="../img/Chat.jpg" alt="Online Therapy" />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <Process />
            </div>
        </div>
    )
}

export default Home


