// src/components/Layout.js
import React from 'react';
import NavbarMain from './NavbarMain';
import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import '../css/navbar-main.css';


const Layout = ({ menuoptions }) => {
    return (
        <div id="canvas" className="psyd-background-pattern">
            <div id="box_wrapper" class="container-fluid pt-0 px-0 shadow-lg rounded psyd-primary-bg-color">
                <div className="container-fluid shadow-lg p-0 mt-0">
                    <Header />
                </div>
                <div className="container-fluid psyd-secondary-bg-color ">
                    <NavbarMain menuoptions={ menuoptions }/>
                </div>
                <div className="container-fluid outlet px-1">
                    <Outlet />  {/* This will render the child routes */}
                </div>
                <div className="container-fluid psyd-secondary-bg-color">
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Layout;
