// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-main {
    position: fixed;
    top: 0;
    width: 100%;
    align-items: center;
    z-index: 10;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: larger;
    color: white;
}

.line{
    width: 100%;
    height: 2px;
    background-color: black;
}

.outlet{
    height: 100%;
    min-height: 70vh;
}`, "",{"version":3,"sources":["webpack://./src/css/navbar-main.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,WAAW;IACX,mBAAmB;IACnB,WAAW;IACX,4DAA4D;IAC5D,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,WAAW;IACX,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".nav-main {\n    position: fixed;\n    top: 0;\n    width: 100%;\n    align-items: center;\n    z-index: 10;\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\n    font-size: larger;\n    color: white;\n}\n\n.line{\n    width: 100%;\n    height: 2px;\n    background-color: black;\n}\n\n.outlet{\n    height: 100%;\n    min-height: 70vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
