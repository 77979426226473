import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';



const PsychologicalConcern = () => {
    const [refvalues, setrefvalues] = useState([]);
    const [psychologicalconcernID, setpsychologicalconcernID] = useState(1);

    const fetchPsychologicalConcern = async () => {
        try {
            const response = await axios.get('/api/allpsychologicalconcern');
            const temprefvalues = response.data.refvalues;
            const parserefvalues = temprefvalues?.map(temprefvalue => JSON.parse(temprefvalue)) || [];
            setrefvalues(parserefvalues);
        } catch (error) {
            console.error('Error fetching themes', error);
        }
    };
    // Function to handle radio button selection
    const handleRadioChange = (event) => {
        setpsychologicalconcernID(event.target.value);
        console.log("Selected professional status:", event.target.value);
        Cookie.set('psychologicalconcernid', event.target.value);
    };
    useEffect(() => {
        const sessioninfoCookie = Cookies.get('sessioninfo');
        let sessioninfo = {};
        if (sessioninfoCookie) {
            sessioninfo = JSON.parse(sessioninfoCookie);
            setpsychologicalconcernID(sessioninfo.concern_id);
        }
        fetchPsychologicalConcern();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Psychological Concerns</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        importance of Psychological Concerns
                    </div>
                </div>
            </div>
            <div className="container mt-3 psyd-primary-bg-color d-flex justify-content-center align-items-top" style={{ minHeight: '50vh' }}>
                <div className="col">
                    <div className="list-group p-0 d-flex justify-content-center align-items-top">
                        {refvalues?.map((refvalue, index) => (
                            <Form.Check
                                key={index}
                                type="radio"
                                id={`refvalue-${index}`}
                                label={
                                    <span style={{ marginLeft: '20px' }}>{refvalue.concern_name}</span> // Add spacing here
                                }
                                value={refvalue.concern_id}
                                name="refvalue" // Grouping the radio buttons
                                className="psyd-secondary-bg-color rounded mb-2"
                                onChange={handleRadioChange}
                                checked={psychologicalconcernID === refvalue.concern_id}
                                style={{ height: '60px', display: 'flex', alignItems: 'center' }} // Adjust height and center alignment
                            />
                        ))}
                    </div>
                </div>
            </div>
            <br />
            <Link to={'/consent'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Next</Button>
            </Link>

        </div>
    );
}

export default PsychologicalConcern;
