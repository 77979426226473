let campfireTheme = {
    colors: {
        background: '#fbe7d7', 
        secondary: '#8f5856',
        tertiary: '#c9c0a7'
    },
    font: {
        color: 'black'
    }
}

export default campfireTheme