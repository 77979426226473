import React from 'react';
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Cookies from 'js-cookie';

const Profile = ({ changeMenuOption }) => {
  const handleLogout = () => {
    const useridcookieExists = Cookies.get('userid') !== undefined;
    if (useridcookieExists) {
      Cookies.remove('userid'); // Use string literal for cookie name
    }

    const userinfocookieExists = Cookies.get('userinfo') !== undefined;
    if (userinfocookieExists) {
      Cookies.remove('userinfo'); // Use string literal for cookie name
    }
    changeMenuOption('login', 'Login', '/login');
  };

  return (

    <div id='homediv' className="container-fluid px-0">
      <div className="container-fluid px-4 shadow-lg rounded">
        <div className="row mt-2 mb-0 rounded">
          <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
            <h1>Profile</h1>
          </div>
        </div>
        <div className="row p-3 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded justify-content-center">
          <div className="col-auto">
            <Link to='/theme-select'>
              <Button>Customize Website Theme</Button>
            </Link>
          </div>
          <div className="col-auto">
            <Link to='/'>
              <Button onClick={handleLogout}>Logout</Button>
            </Link>
          </div>
          <div className="col-12 text-center p-1">
            More profile update options coming soon.
          </div>
        </div>
      </div>
    </div>
  );
};
export default Profile;
