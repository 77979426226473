// src/therapy.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';

// Set axios default settings to include cookies
axios.defaults.withCredentials = true;

function Messages() {
    const [messages, setMessages] = useState([]);
    const [newClientInput, setNewClientInput] = useState('');
    const [isProcessing, setIsProcessing] = useState(false);
    const scrollRef = useRef(null);
    const [sessionTime, setsessionTime] = useState(120);
    const [photourl, setPhotourl] = useState('./img/logo.png');

    const openPDF = () => {
        // Retrieve sessionid from cookies
        const sessionid = Cookies.get('sessionid'); // Assuming 'sessionid' is stored in a cookie

        if (sessionid) {
            // Construct the URL for the new tab with sessionid as a query string
            const pdfUrl = `/api/viewpdf?sessionid=${sessionid}`;
            // Open the URL in a new tab
            window.open(pdfUrl, '_blank');            
        } else {
            console.error('Session ID not found');
        }
    };
    const fetchMessages = async () => {
        try {
            const response = await axios.get('/api/therapy');
            const sessiontimeused = response.data.sessiontimeused;
            const sessionTimeLeft = 120 - sessiontimeused;
            setsessionTime(sessionTimeLeft);
            const sessioninfoCookie = Cookies.get('sessioninfo');
            const response2 = await axios.get('/api/sessioninfo');
            const sessioninfo = response2.data.sessioninfo;
            Cookie.set('sessioninfo', sessioninfo);
            setPhotourl(JSON.parse(Cookies.get('sessioninfo')).photo_url);
            const tempmessages = response.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
            scrollToBottom(); // Scroll to the last message after fetching
        } catch (error) {
            console.error('Error fetching messages', error);
        }
    };

    const handleSendInput = async () => {
        if (newClientInput.trim() === '') return;
        setIsProcessing(true); // Disable button and show processing state
        try {
            const res = await axios.post('/api/therapy', { content: JSON.stringify({ "clientinput": newClientInput }) });
            const sessiontimeused = res.data.sessiontimeused;
            const sessionTimeLeft = 120 - sessiontimeused;
            setsessionTime(sessionTimeLeft);
            const sessionid = res.data.sessionid;
            Cookie.set('sessionid', sessionid);  // Store sessionid in cookies
            const sessioninfoCookie = Cookies.get('sessioninfo');
            const response = await axios.get('/api/sessioninfo');
            const sessioninfo = response.data.sessioninfo;
            Cookie.set('sessioninfo', sessioninfo);
            setNewClientInput('');
            setPhotourl(JSON.parse(Cookies.get('sessioninfo')).photo_url);
            const tempmessages = res.data.therapyhistory;
            const parsedMessages = tempmessages?.map(tempmessage => JSON.parse(tempmessage)) || [];
            setMessages(parsedMessages);
            scrollToBottom(); // Scroll to the last message after fetching
            //await fetchMessages(); // Wait for messages to fetch
        } catch (error) {
            console.error('Error sending input', error);
        } finally {
            setIsProcessing(false); // Re-enable button
        }
    };

    const scrollToBottom = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    };

    useEffect(() => {
        const cookieExists = Cookies.get('sessionid') !== undefined;
        if (cookieExists) {
            fetchMessages();
        }
    }, []);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Prevent form submission on Enter key press
        }
    };
    const maxLength = 300;
    const remainingChars = maxLength - newClientInput.length;
    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>My Session</h1>
                    </div>
                </div>
            </div>
            <div className="container-fluid px-2 shadow-lg">
                <div className="row mt-0 mb-0">
                    <div className="col-md-8 psyd-primary-bg-color psyd-font-light  d-flex align-items-start text-center">
                        {sessionTime}&nbsp;Therapy minutes left this month
                    </div>
                    <div className="col-md-4 psyd-primary-bg-color psyd-font-light  d-flex align-items-start text-center">&nbsp;</div>
                </div>
            </div>
            <div className="container-fluid px-3 shadow-lg rounded">
                <div className="row">
                    <div className="col-md-8 px-0 mb-0"> {/* Added margin-bottom to create space between columns */}
                        <div className="border border-3 psyd-primary-bg-color psyd-font-light text-center rounded-top">
                            Session with PSYD
                        </div>
                        <div
                            className="px-1 mb-0 border border-top-0 border-3 rounded-left rounded-right shadow-lg pattern"
                            style={{ height: '400px', overflowY: 'scroll', scrollbarWidth: 'thin', borderColor: 'white' }} // Change border color to white
                            ref={scrollRef}
                        >
                            <ul className="mx-0 px-0">
                                {messages.length === 0 && <p className="text-center psyd-chat-response">How can I help you today?</p>}
                                {messages?.map((record) => (
                                    <li className="psyd-chat" key={record.history_id}> {/* Added key to list item */}
                                        <div className="d-flex justify-content-end mb-2 mt-2">
                                            <div className="p-1 rounded psyd-chat-input text-right" style={{ maxWidth: '70%' }}>
                                            {record.input_text}
                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-start">
                                            <div className="p-1 rounded psyd-chat-response" style={{ maxWidth: '70%' }}>
                                            <img
                                                src={`./img/${photourl}`}
                                                style={{
                                                    width: '25px', // Adjust width as needed
                                                    height: '25px',
                                                    borderRadius: '50%', // Make image circular
                                                    margin: '0px', // Add space between images
                                                    cursor: 'pointer',
                                                }}
                                            />
                                            {record.response_text}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Form className="mt-3 mb-0">
                            <Form.Group className="d-flex">
                                <Form.Control
                                    as="textarea"
                                    rows={3}
                                    value={newClientInput}
                                    onChange={(e) => setNewClientInput(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                    placeholder="Type your message..."
                                    maxLength={maxLength}
                                />
                                <Button
                                    variant="primary"
                                    onClick={handleSendInput}
                                    className="ms-2"
                                    disabled={isProcessing} // Disable button while processing
                                >
                                    {isProcessing ? 'Processing...' : 'Send'} {/* Change button text */}
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={openPDF}
                                    className="ms-2"
                                >Download PDF
                                </Button>
                            </Form.Group>
                        </Form>
                        <div className="psyd-primary-bg-color psyd-font-light"> {/* Added margin-bottom to create space between columns */}
                        {remainingChars} characters remaining
                        </div>

                    </div>
                    <div className="col-md-4 px-2">
                        <div>
                            <div className="border border-3 rounded-top psyd-primary-bg-color psyd-font-light text-center">
                                Tips for an effective session
                            </div>
                            <div className="px-1 border border-top-0 border-3 rounded-left rounded-right">
                                <ListGroup className="shadow-lg rounded p-1">
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi bi-check-circle">
                                        <b>&nbsp;Be Open and Honest</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Set Clear Goals</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Regularly Engage</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Try Rephrasing questions</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Supplement with Human Therapy</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Be Patient with AI </b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Combine with Self-Care</b>
                                    </ListGroup.Item>
                                    <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-1 mb-1 bi-check-circle">
                                        <b>&nbsp;Remember, It's a Tool</b>
                                    </ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Messages;
