// src/App.js
import React, { useCallback, useState, useEffect } from 'react';
import './css/app.css';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Messages from './components/chat';
import Home from './components/Home';
import NavbarMain from './components/NavbarMain';
import campfireTheme from './components/CampfireTheme';
import luxuryTheme from './components/LuxuryTheme';
import starryTheme from './components/StarryTheme';
import 'bootstrap/dist/css/bootstrap.min.css';
import Login from './components/Login';
import SignUp from './components/SignUp';
import Copyright from './components/Copyright';
import ThemeSelection from './components/ThemeSelection';
import About from './components/About';
import Plans from './components/plans';
import Layout from './components/Layout';
import PricingPage from './components/pricing';
import ProcessSubscription from './components/processsubscription';
import Sessions from './components/sessions';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import ProcessLogin from './components/processLogin';
import Process from './components/process';
import Profile from './components/profile';
import AgeGroup from './components/agegroup';
import RelationshipStatus from './components/relationshipstatus';
import ProfessionalStatus from './components/professionalstatus';
import JobCategory from './components/jobcategory';
import PsychologicalConcern from './components/psychologicalconcern';
import Specializations from './components/specializations';
import Plans2 from './components/plans2';
import Consent from './components/consent';
import Terms from './components/terms';
import Personas from './components/personas';
import TherapistProfile from './components/therapistprofile';
import FAQ from './components/faq';
import Resources from './components/resources';
import User from './components/user';

function App() {
  const [currentTheme, setCurrentTheme] = useState('starryTheme')
  const [theme, setTheme] = useState('starryTheme');
  const handleThemeChange = (newTheme) => {
    setCurrentTheme(newTheme);
    setTheme(newTheme);
    Cookie.set('theme', newTheme);  // Store sessionid in cookies

  }
  useEffect(() => {
    let selectedTheme ='default';
    const themecookieExists = Cookies.get('theme') !== undefined;    
    if(themecookieExists){ selectedTheme = Cookies.get("theme");
    }else {selectedTheme = "default";
    }

    if (selectedTheme === 'default') { import('./css/psyd-calm.css') }
    else if (selectedTheme === 'campfireTheme') { import('./css/psyd-campfire.css') }
    else if (selectedTheme === 'luxuryTheme') { import('./css/psyd-luxury.css') }
    else if (selectedTheme === 'starryTheme') { import('./css/psyd-starry.css') }
    else if (selectedTheme === 'blackTheme') { import('./css/psyd-black.css') }
    else if (selectedTheme === 'whiteTheme') { import('./css/psyd-white.css') }
    else if (selectedTheme === 'aquaTheme') { import('./css/psyd-aqua.css') }
    else if (selectedTheme === 'calmTheme') { import('./css/psyd-calm.css') }

    const useridcookieExists = Cookies.get('userid') !== undefined;
    if (useridcookieExists) {
      changeMenuOption('login', 'Profile', '/profile');
    }else {changeMenuOption('login', 'Login', '/login');
    }


  }, [theme]);

  const [menuoptions, setMenuOptions] = useState({
    home: { name: 'Home', link: '/' },
    process: { name: 'How it Works', link: '/process' },
    plans: { name: 'Therapy Options', link: '/plans' },
    specializations: { name: 'Specializations', link: '/specializations' },
    faq: { name: 'FAQ', link: '/faq' },
    resources: { name: 'Resources', link: '/resources' },
    about: { name: 'Meet the Team', link: '/about' },
    login: { name: 'Login', link: '/login' },
  });

  const changeMenuOption = useCallback((optionKey, newName, newLink) => {
    setMenuOptions(prevOptions => ({
      ...prevOptions,
      [optionKey]: { name: newName, link: newLink }, // Update name and link
    }));
  }, []); // Include dependencies if necessary
  
  return (

    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout menuoptions={menuoptions} />}>
            <Route index path='/' element={<Home />} />
            <Route path='/messages' element={<Messages />} />
            <Route path='/sessions' element={<Sessions />} />
            <Route path='/login' element={<Login />} />
            <Route path='/sign-up' element={<SignUp />} />
            <Route path='/theme-select' element={<ThemeSelection handleThemeChange={handleThemeChange} />} />
            <Route path='/copyright' element={<Copyright />} />
            <Route path='/about' element={<About />} />
            <Route path='/plans' element={<Plans2 />} />
            <Route path='/purchase' element={<PricingPage />} />
            <Route path='/processsubscription' element={<ProcessSubscription />} />
            <Route path='/processlogin' element={<ProcessLogin changeMenuOption= {changeMenuOption}/>} />
            <Route path='/process' element={<Process />} />
            <Route path='/profile' element={<Profile changeMenuOption= {changeMenuOption}/>} />
            <Route path='/agegroup' element={<AgeGroup />} />
            <Route path='/relationshipstatus' element={<RelationshipStatus />} />
            <Route path='/professionalstatus' element={<ProfessionalStatus />} />
            <Route path='/jobcategory' element={<JobCategory />} />
            <Route path='/psychologicalconcern' element={<PsychologicalConcern />} />
            <Route path='/specializations' element={<Specializations />} />
            <Route path='/consent' element={<Consent />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/personas' element={<Personas />} />
            <Route path='/therapistprofile' element={<TherapistProfile />} />
            <Route path='/faq' element={<FAQ />} />
            <Route path='/resources' element={<Resources />} />
            <Route path='/user' element={<User />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;


{/* <header className="App-header">
        <h1>Welcome to a Virtual Therapist using Generative AI.</h1>
        <h3>Please enter your concerns, hit enter and I will provide a response for you.</h3>
        </header> */}

