import { Link } from "react-router-dom";
import '../css/footer.css'

const Footer = () => {

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-0 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded align-items-center">
                    <div className="col-12 col-md-6 psyd-secondary-bg-color p-1 rounded-top d-flex justify-content-center">
                        Copyright 2024 <a href="http://mlai.co" target="_blank" rel="noopener noreferrer">&nbsp;MLAI Solutions LLC</a>. All Rights Reserved
                    </div>
                    <div className="col-12 col-md-6 psyd-secondary-bg-color p-1 rounded-top d-flex justify-content-center">
                        <Link to="/terms">Terms and Conditions</Link>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer;