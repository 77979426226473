import React, { useEffect } from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom"
import { Button } from "react-bootstrap"



const ProcessSubscription = () => {
  const location = useLocation();

  // Use URLSearchParams to parse the query string
  const query = new URLSearchParams(location.search);
  let subscriptionID = query.get('subscriptionID');
  let id = '';
  if (!subscriptionID) {
    id = query.get('id');
    subscriptionID = id;
  }
  let customerID = query.get('customerID');
  const state = query.get('state');
  const userid = Cookies.get("userid");

  //const postData = {"subscriptionID" : subscriptionID, "userid" : userid};
  useEffect(() => {
    // Make POST request to Flask backend
    const postData = { "subscriptionID": subscriptionID, "customerID": customerID }
    axios.post('/api/processsubscription', postData)
      .then(response => {
        const usersubscriptioninfo = JSON.parse(response.data.usersubscriptioninfo);
        Cookie.set('usersubscriptioninfo', JSON.stringify(usersubscriptioninfo));
      })
      .catch(error => {
        console.error('Error posting data:', error);
      });
  }, []); // Add myVar to dependencies

  return (
    <div id='homediv' className="container px-0">
      <div className="container px-0 psyd-secondary-bg-color psyd-font shadow-lg rounded">
        <div className="row mt-1">
          <div className="col p-3">
            Your subscription purchase was processed successfully. Please start a new session.
          </div>
          <br />
            <Link to={'/sessions'} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button>Start a Session</Button>
            </Link>
        </div>
      </div>
    </div>
  );
};

export default ProcessSubscription;
