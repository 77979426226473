import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

const Specializations = () => {
    const [refvalues, setRefValues] = useState([]);
    const [psychologicalConcernID, setPsychologicalConcernID] = useState([]);

    const fetchPsychologicalConcern = async () => {
        try {
            const response = await axios.get('/api/allpsychologicalconcern');
            const tempRefValues = response.data.refvalues;
            const parsedRefValues = tempRefValues?.map(tempRefValue => JSON.parse(tempRefValue)) || [];
            console.error(parsedRefValues)
            setRefValues(parsedRefValues);
        } catch (error) {
            console.error('Error fetching psychological concerns', error);
        }
    };

    const handleCardClick = (id) => {
        setPsychologicalConcernID(id);
        Cookie.set('psychologicalconcernid', id);
        console.log("Selected psychological concern:", id);
    };

    useEffect(() => {
        const sessionInfoCookie = Cookies.get('sessioninfo');
        if (sessionInfoCookie) {
            const sessionInfo = JSON.parse(sessionInfoCookie);
            setPsychologicalConcernID(sessionInfo.concern_id);
        }
        fetchPsychologicalConcern();
    }, []);

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Our Specializations</h1>                        
                    </div>
                </div>
                <div className="row px-1 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg rounded">
                    <div className="col p-1 rounded">
                        We can help you deal with the following mental health support areas.
                    </div>
                </div>
            </div>
            <div className="container-fluid mt-3 psyd-primary-bg-color">
                <Row className="justify-content-start">
                    {refvalues?.map((refvalue, index) => (
                        <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb-3">
                            <Card 
                                className={`text-left psyd-secondary-bg-color cursor-pointer ${psychologicalConcernID === refvalue.concern_id ? 'border border-primary' : ''}`} 
                                onClick={() => handleCardClick(refvalue.concern_id)}
                                style={{ height: '100%' }}
                            >
                                <Card.Body>
                                    <Card.Title>{refvalue.concern_name}</Card.Title>
                                    <Card.Body>{refvalue.concern_description.substring(0, 300)}</Card.Body>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </div>
    );
}

export default Specializations;
