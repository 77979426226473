import React, { useState, useEffect } from 'react';
import { Button, Form } from "react-bootstrap";
import axios from 'axios';
import '../css/home.css';
import { Link } from "react-router-dom";
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';
import ListGroup from 'react-bootstrap/ListGroup';

const Resources = () => {

    return (
        <div id='homediv' className="container-fluid px-0">
            <div className="container-fluid px-4 shadow-lg rounded">
                <div className="row mt-2 mb-0 rounded">
                    <div className="col-md-12 psyd-primary-bg-color p-3 psyd-font-accent rounded-top text-center">
                        <h1>Resources</h1>
                    </div>
                </div>
                <div className="row px-0 mt-0 mb-1 psyd-secondary-bg-color psyd-font shadow-lg align-items-center rounded">
                    <div className="col-md-12 p-3 d-flex justify-content-center">
                        The Resources section is designed to provide you with a variety of tools and information to support your mental health journey. Here, you'll find links to external resources, such as mental health organizations, crisis hotlines, and online therapy platforms. These resources can offer additional support, guidance, and community. We encourage you to explore these options and find what works best for you. Remember, you're not alone, and there are many people and resources available to help.
                    </div>
                </div>
            </div>
            <div className="container mt-3 psyd-primary-bg-color d-flex justify-content-center align-items-top" style={{ minHeight: '50vh' }}>
                <div className="col">
                    <ListGroup className="p-0">
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>National Alliance on Mental Illness (NAMI). </strong><br></br>
                            NAMI provides advocacy, education, and support to individuals living with mental health conditions. Their website offers a variety of resources, including educational programs, support groups, and mental health services.<br></br>
                            Website: <a href="http://nami.org" target="_blank"> NAMI.org</a></ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>The National Institute of Mental Health (NIMH)</strong><br></br>
                        NIMH is the U.S. government’s lead agency for research on mental health. Their website offers information on mental health disorders, research findings, and available treatments.<br></br>
                            Website: <a href="https://www.nimh.nih.gov/" target="_blank">www.nimh.nih.gov</a></ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>Anxiety and Depression Association of America (ADAA)</strong><br></br>
                            ADAA provides resources to support people living with anxiety, depression, OCD, PTSD, and related disorders. The platform includes articles, treatment options, and virtual support groups.<br></br>
                            Website: <a href="https://adaa.org/" target="_blank">ADAA.org</a></ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>Mental Health America (MHA)</strong><br></br>
                            MHA is a leading nonprofit organization that promotes mental health and well-being. It provides valuable resources on mental health conditions, prevention, and recovery, including free mental health screenings.<br></br>Website: <a href="https://www.mhanational.org/" target="_blank">MHNational.org</a></ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>MentalHealth.gov. </strong>
                            A comprehensive government website with information on mental health conditions, treatment options, and crisis resources.<br></br>Website:<a href="http://mentalhealth.gov" target="_blank"> MentalHealth.gov</a></ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>988 Suicide & Crisis Lifeline</strong><br></br>
                            A confidential 24/7 helpline that provides immediate assistance to anyone in distress. If you're experiencing a mental health crisis or contemplating self-harm, this lifeline connects you with a trained counselor who can provide support.<br></br>
                            Website: <a href="https://988lifeline.org/" target="_blank">988lifeline.org</a><br></br>Call: 988</ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>Crisis Text Line </strong>
                            Text HOME to 741741 to connect with a crisis counselor.</ListGroup.Item>
                        <ListGroup.Item className="psyd-secondary-bg-color psyd-font shadow-lg rounded p-3 mb-2"><strong>Psychology Today </strong>Offers articles, quizzes, and directories of therapists.<br></br>Website: <a href="http://PsychologyToday.com" target="_blank">PsychologyToday.com</a></ListGroup.Item>
                    </ListGroup>
                </div>
            </div>
        </div>
    );
}

export default Resources;
