import Pricify from '@chargebee/atomicpricing';
import { useEffect } from 'react';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

function PricingPage() {
  const userCookie = Cookies.get('userinfo');
  let userinfo = {};
  if (userCookie) {
    userinfo = JSON.parse(userCookie);
  }
  useEffect(() => {
    Pricify.init();
    Pricify.setVisitor({
      firstName: userinfo.first_Name,
      lastName: userinfo.last_name,
      email: userinfo.email,
    });
  }, []);

  return (
    <div
    id="pricify-hosted-pricing-page"
    data-pricify-site="01J9SWKX8XQTDVMA8KPDBPYCRM"
    data-pricify-pricingpage="01J9SWKXWD4GR9M9GHVSZQ46SJ"
    data-pricify-viewport-defaultheight="673px"
  ></div>
      
  );
}
export default PricingPage